@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Regular.ttf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Black.ttf) format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Bold.ttf) format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Light.ttf) format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Medium.ttf) format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-SemiBold.ttf) format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: montserrat;
  src: url(Montserrat/Montserrat-Thin.ttf) format("opentype");
  font-weight: 100;
  font-style: normal;
}
